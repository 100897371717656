import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import './assets/font/font.css'
import i18n from './locals'

// import 'ant-design-vue/dist/reset.css';

const app = createApp(App);

app.use(i18n)
app.use(router).mount('#app')
