<template>
    <div>
        <div class="container">
            <div class="route">
                <div v-for="(item, index) in breadcrumb" :key="index" style="display: flex; align-items: center;">
                    <router-link :to="item.link">
                        <img v-if="item.name === 'Home'" src="@/assets/icon/home.png" alt="">
                        <span v-else :class="[index === breadcrumb.length - 1 ? 'active' : '']">{{ item.name }}</span>
                    </router-link>
                    <img class="arrow" v-if="index !== breadcrumb.length - 1" src="@/assets/icon/arrow.png" alt="">
                </div>
            </div>
            <div class="qrbox">
                <div class="qrbox-item">
                    <img :src="result.param1" alt="">
                    <p>{{ $t('anzhuobanxiazai') }}</p>
                </div>
                <div class="qrbox-item">
                    <img :src="result.param2" alt="">
                    <p>{{ $t('pingguobanxiazai') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getCommonApi} from "@/server/api";
import { useI18n } from "vue-i18n";

export default {
    props: {
        list: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            t: null,
            breadcrumb: [
                {name: 'Home', link: '/'}
            ],
            result: {},
        }
    },
    mounted() {
        const titles = this.$route.meta.title
        const pathList = this.$route.path.split('/')
        pathList.shift()
        let path = ''
        titles.forEach((item, index) => {
            path += `/${pathList[index]}`
            this.breadcrumb.push({
                name: item,
                link: path
            })
        });

        this.getCommon()
    },
    methods: {
        async getCommon() {
            const res = await getCommonApi({
                id: '9'
            })
            if (res.code === 200) {
                this.result = res.data
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    // width: calc(100% - 460px);
    height: 100px;
    margin: 0 80px;
    padding: 0 100px;
    // background: rgba(#872228, 0.5);
    border-radius: 156px;
}

.route {
    display: flex;
    align-items: center;
    // margin-top: 20px;

    img{
        width: 48px;
    }

    span {
        color: #fff;
        font-size: 24px;
    }

    .active {
        color: #F9C230;
        padding: 10px 20px;
        background: #A5292E;
        border-radius: 40px;
    }

    .arrow {
        margin: 0 20px;
        width: 20px;
    }
}

.qrbox {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    .qrbox-item {
        display: flex;
        // align-items: center;
        flex-direction: column;
        margin-bottom: 20px;

        p {
            // writing-mode: vertical-rl;
            text-align: center;
            color: #fff;
            font-size: 18px;
            margin-top: 6px;
        }

        img {
            width: 100px;
            height: 100px;
            margin: 0 auto;
        }
    }
}
</style>