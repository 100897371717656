<template>
    <pageHeader />
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb></breadcrumb>
        <img class="banner" src="@/assets/ecosystem/banner.png" alt="">
        <div class="banner-title">
            <p>{{ $t('shengtaixitong') }}</p>
            <p>ECOSYSTEM</p>
        </div>
        <div class="content">
            <div class="title">
                <img src="@/assets/qifu/title3.png" alt="">
                <p>{{ $t('jieshao') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="result.param1"/>
            </div>
            <div class="title">
                <img src="@/assets/ecosystem/title.png" alt="">
                <p>{{ $t('tesegongneng') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="result.param2"/>
            </div>
        </div>
    </div>
    <pageFooter />
</template>

<script setup>
import { ref, onMounted } from 'vue'

import {getCommonApi} from "@/server/api"

onMounted(() => {
    getCommon()
})

const result = ref({})
async function getCommon(){
    const res = await getCommonApi({
        id: '6'
    })
    console.log('res', res);
    if(res.code === 200){
        result.value = res.data
    }
}

</script>

<script>
import pagination from "@/components/pagination"
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import breadcrumb from '@/components/breadcrumb'
import enter from "@/components/enter.vue"

export default {
    components: {
        pagination,
        pageHeader,
        pageFooter,
        breadcrumb,
        enter
    }
}
</script>

<style lang="scss" scoped>
.container{
    position: relative;
    padding: 20px 0 120px;

    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    .banner{
        width: 100%;
        position: relative;
        margin-top: -176px;
        z-index: -1;
    }
    .banner-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -400px;
        text-align: center;
        p:nth-child(1){
            font-size: 70px;
            color: #fff;
            margin-bottom: 20px;
            font-weight: bold;
            letter-spacing: 15px;
            -webkit-text-stroke: 2px #fff;
        }
        p:nth-child(2){
            font-size: 26px;
            color: #fff;
        }
    }

    .content, .list-box{
        margin: 200px auto 0;
        max-width: 1400px;

        .title{
            p{
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }
        .text{
            margin-top: 50px;
            margin-bottom: 100px;
            p{
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 20px;
                line-height: 44px;
            }
        }
    }

    .list-box{
        margin: 200px auto 0;
        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .search{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 440px;
                background: #2B2B2B;
                padding: 10px 20px;
                border-radius: 50px;
                border: 1px solid #872228;
                img{
                    width: 42px;
                    height: 42px;
                }
                input{
                    width: 100%;
                    padding: 0 15px;
                    background: transparent;
                    border: none;
                    border-left: 1px solid #fff;
                    height: 36px;
                    margin-left: 15px;
                    font-size: 32px;
                    color: #fff;
                    &:focus-visible{
                        outline: none;
                    }
                }
            }
        }

        .list{
            .item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 120px;
                &:nth-child(even){
                    flex-direction: row-reverse;
                }
                img{
                    width: 48%;
                    height: 338px;
                    object-fit: cover;
                }
                .info{
                    width: 48%;
                    .title{
                        font-size: 54px;
                        margin-bottom: 20px;
                        color: #fff;
                    }
                    .desc{
                        font-size: 28px;
                        color: #F0F0F0;
                        margin-bottom: 20px;
                    }
                    .more{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 260px;
                        font-size: 24px;
                        background: linear-gradient(to right, #2A5BE9, #963FF0);
                        padding: 10px 20px;
                        border-radius: 30px;
                        color: #F0F0F0;
                        cursor: pointer;
                        img{
                            width: 36px;
                            height: 36px;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

    }
}
</style>