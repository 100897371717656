<template>
    <div>
        <div class="enter">
            <div>
                <p>{{ $t('juankuanrukou') }}</p>
                <p>NEEDS FUNDING</p>
            </div>
        </div>
    </div>
</template>
<script> 

</script>

<style lang="scss" scoped>
    
    .enter{
        display: flex;
        flex-direction: row-reverse;
        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 370px;
            height: 100px;
            background-image: url('../assets/enter/enter.png');
            background-size: 100% 100%;
            padding-left: 40px;
            p{
                color: #fff;
                &:first-child{
                    font-size: 24px;
                }
                &:last-child{
                    font-size: 20px;
                }
            }
        }
    }
</style>