<template>
    <div>
        <div class="footer">
            <img class="bg" src="@/assets/footer/endbg.png" alt="">
            <canvasLine :width="1400" :height="224" :ds="20" class="canvas-line"></canvasLine>
            <div class="center">
                <div>
                    <p class="text" v-html="result.param6"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'

    import {getCommonApi} from "@/server/api"

    onMounted(() => {
        getCommon()
    })

    const result = ref({})
    async function getCommon(){
        const res = await getCommonApi({
            id: '109'
        })
        console.log('res', res);
        if(res.code === 200){
            result.value = res.data
        }
    }

</script>

<style lang="scss" scoped>
.footer {
    position: relative;
    height: 224px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .canvas-line{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.7;
    }

    .center{
        position: relative;
        width: 90vw;
        margin: 0 auto;
        z-index: 9;

        div{
            font-size: 18px;
            text-align: center;
            color: #fff;
            margin-top: 10px;
        }
    }
}
</style>