<template>
    <div class="pagination">
        <img v-if="current == 1" src="@/assets/enter/prev_d.png" alt="">
        <img v-else src="@/assets/enter/prev.png" alt="" @click="prev">
        <div class="page-number">
            <p>{{ current }}</p>
            <p>/</p>
            <p>{{ lastPage }}</p>
        </div>
        <img v-if="current == lastPage" src="@/assets/enter/next_d.png" alt="">
        <img v-else src="@/assets/enter/next.png" alt="" @click="next">
    </div>
</template>

<script>
export default {
    props: {
        lastPage: {
            type: Number,
            default: 0
        },
    },
    data(){
        return {
            current: 1
        }
    },
    methods: {
        prev(){
            this.current = this.current > 1 ? this.current - 1 : 1
            this.$emit('onChange', this.current)
        },
        next(){
            this.current = this.current < this.lastPage ? this.current + 1 : this.lastPage
            this.$emit('onChange', this.current)
        }
    }
}
</script>


<style lang="scss" scoped>
    .pagination{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -72px;
        margin-top: 70px;

        .page-number{
            display: flex;
            align-items: center;
            color: #F9C230;
            font-size: 24px;
            margin: 0 14px;
            padding: 4px 20px;
            border: 1px solid #F9C230;
            border-radius: 30px;
        }
        img{
            cursor: pointer;
        }
    }
</style>