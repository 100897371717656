<template>
    <pageHeader />
    <div class="container">
        <img class="bg" src="@/assets/user/bg.png" alt="">
        <div class="title-box">
            <div class="title">
                <img src="@/assets/user/title3.png" alt="">
                <p>修改信息</p>
            </div>
        </div>
        <div class="form">
            <div class="left">
                <div class="form-item">
                    <p class="label">{{ $t('user.xiugaixinxi') }}：</p>
                    <input v-model="name" type="text" :placeholder="`${$t('placeholder3')}`">
                </div>
                <div class="form-item" style="display:flex; align-items:center;margin-top:50px">
                    <p class="label">{{ $t('user.xingbie') }}：</p>
                    <div class="checkbox">
                        <div class="checkbox-item" v-for="item in sexs" :key="item.name" @click="selectSex(item)">
                            <img v-if="item.checked" src="@/assets/login/checkbox_A.png" alt="">
                            <img v-else src="@/assets/login/checkbox.png" alt="">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class="form-item" style="margin-top:50px">
                    <div class="label" style="display:flex; align-items:center">
                        {{ $t('user.cishanshezhi') }}：
                        <img src="@/assets/icon/warn.png" alt="" width="25" height="25" style="margin-right: 5px">
                        <router-link to="/user/notes">{{ $t('user.cishanshuoming') }}</router-link>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="form-item">
                    <p class="label">{{ $t('user.gerenjianjie') }}</p>
                    <textarea v-model="content" type="text" :placeholder="`(${$t('jieshaoziji')})`"></textarea>
                </div>
            </div>
        </div>

        <div class="avatar">
            <div v-for="img in avatarList" :key="img.avatar_id" @click="selectAvatar(img)">
                <p v-if="activeAvatar === img.avatar_id">{{ $t('user.shiyongzhong') }}</p>
                <img :src="img.picture" alt="">
            </div>
        </div>

        <div class="options">
            <div class="btn save" @click="handleSave">{{ $t('user.baocun') }}</div>
            <div class="btn" @click="handleCencle">{{ $t('user.quxiao') }}</div>
        </div>
    </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import { message } from 'ant-design-vue';
import { getAvatarListApi, getUserInfoApi, updateUserInfoApi } from "@/server/api"

export default {
    components: {
        pageHeader,
        pageFooter
    },
    data() {
        return {
            avatarList: [],
            sexs: [{
                name: this.$t('user.nan'),
                value: '1',
                checked: true
            },{
                name: this.$t('user.nv'),
                value: '2',
                checked: false
            }],
            name: '',
            content: '',
            activeAvatar: 0
        }
    },
    mounted(){
        this.getUserInfo()
        this.getAvatarList()
    },
    methods: {
        async getAvatarList(){
            const res = await getAvatarListApi()
            if(res.code === 200){
                this.avatarList = res.data
            }
            console.log('res', res);
        },
        async getUserInfo(){
            const res = await getUserInfoApi()
            if(res.code === 200){
                this.name = res.data.nickname
                this.content = res.data.content
                this.activeAvatar = res.data.avatar_id
                this.sexs.forEach(s => {
                    s.checked = s.value == res.data.sex
                })
            }
            console.log('res', res);
        },
        selectSex(item){
            this.sexs.forEach(s => s.checked = false)
            item.checked = true
        },
        selectAvatar(item){
            this.activeAvatar = item.avatar_id
        },  


        async handleSave(){
            const params = {
                avatar_id: this.activeAvatar,
                nickname: this.name,
                content: this.content,
                sex: this.sexs.filter(s => s.checked)[0]?.value || 0
            }
            const rules = {
                avatar_id: '请选择头像',
                nickname: '昵称不能为空',
                content: '个人简介不能为空',
                sex: '请选择性别'
            }

            const keys = Object.keys(params)
            for(let key of keys){
                if(!params[key]){
                    message.error(rules[key])
                    return
                }
            }
            const res = await updateUserInfoApi(params)
            if(res.code === 200){
                message.success('更新成功')
                setTimeout(() => {
                    this.$router.replace('/user')
                }, 1000)
            }
        },
        handleCencle(){
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        position: relative;
        padding: 20px 0 120px;

        .bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        .title-box{
            display: flex;
            justify-content: center;
            .title{
                p{
                    font-size: 66px;
                    font-weight: bold;
                    color: #ffe900;
                    letter-spacing: 15px;
                    margin-top: -96px;
                    //-webkit-text-stroke: 2px #ffe900;
                    white-space: pre;
                }
            }
        }
    }

    .form{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 1400px;
        margin: 40px auto 0;

        >div{
            width: 50%;
        }

        .form-item{
            input{
                width: 580px;
                height: 82px;
                padding: 0 40px;
                font-size: 28px;
                color: #fff;
                margin-bottom: 46px;
                outline: none;
                border: none;
                background: rgba(#000, 0.5);
                border-radius: 40px;
                border: 1px solid #000;
                &:focus-visible{
                    border: 1px solid #FFB11B;
                }
            }
            textarea{
                width: 580px;
                height: 300px;
                padding: 20px 30px;
                font-size: 20px;
                color: #fff;
                outline: none;
                border: none;
                background: rgba(#000, 0.5);
                border-radius: 40px;
                border: 1px solid #000;
                &:focus-visible{
                    border: 1px solid #FFB11B;
                }
            }

            .checkbox{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                margin-left: 30px;
                .checkbox-item{
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-size: 30px;
                    margin-right: 100px;
                    img{
                        margin-right: 10px;
                        width: 38px;
                        height: 38px;
                    }
                }
            }
        }

        .label{
            color: #fff;
            font-size: 26px;
            margin-bottom: 20px;

            a{
                color: #fff;
                font-size: 22px;
                border-bottom:1px solid #eee; 
                cursor:pointer
            }
        }
    }

    .avatar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1400px;
        margin: 100px auto 0;

        >div{
            position: relative;
        }

        img{
            width: 300px;
            height: 530px;
        }
        p{
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 26px;
            color: #FFE900;
        }
    }

    .options{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        .btn{
            width: 268px;
            height: 82px;
            border-radius: 40px;
            color: #fff;
            background: #2B2B2B;
            font-size: 40px;
            text-align: center;
            line-height: 80px;
            margin: 0 50px;
            cursor: pointer;
        }
        .save{
            background: linear-gradient(to right, #2A5BE9, #963FF0);
        }
    }
</style>    