<template>
    <pageHeader />
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb></breadcrumb>
        <img class="banner" src="@/assets/art/banner.png" alt="">
        <div class="banner-title">
            <p>{{ $t('siluquanqiu') }}</p>
            <p>SILK ROAD GLOBAL CULTURAL AMBASSADOR</p>
        </div>
        <div class="content">
            <div class="title">
                <img src="@/assets/qifu/title3.png" alt="">
                <p>{{ $t('jieshao') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="result.param1"/>
            </div>
        </div>

        <div class="list-box">
            <div class="header">
                <div class="title">
                    <img src="@/assets/wenhua/title.png" alt="">
                    <p>{{ $t('wenhuashijie') }}</p>
                </div>
                <div class="search">
                    <img src="@/assets/icon/search.png" alt="">
                    <input v-model="searchContent" type="text" :placeholder="(`${$t('placeholder2')}`)" @input="handleInput">
                </div>
            </div>
            <div class="list">
                <div class="list-item" v-for="(item,index) in shopList" :key="index" @click="handleClick(item)">
                    <div class="status-box">
                        <div class="time">
                            <img src="@/assets/icon/time.png" alt="">
                            {{ item.date }}
                        </div>
                    </div>  
                    <img :src="item.picture" alt="">
                    <p class="title">{{ item.title }}</p>
                    <div class="desc">
                        <img src="@/assets/icon/address.png" alt="">
                        {{ item.address }}
                    </div>
                </div>
            </div>
            <pagination 
                :lastPage="lastPage" 
                @onChange="changePage"
            ></pagination>
        </div>
    </div>
    <pageFooter />
</template>

<script>
import pagination from "@/components/pagination"
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import breadcrumb from '@/components/breadcrumb'
import enter from "@/components/enter.vue"
import {getCultureListApi, getCommonApi} from "@/server/api"
import { debounce } from "@/utils/index"

export default {
    components: {
        pagination,
        pageHeader,
        pageFooter,
        breadcrumb,
        enter
    },
    data(){
        return {
            page: 1,
            currentPage: 0,
            lastPage: 0,
            total: 0,
            searchContent: '',
            shopList: [],
            result: {}
        }
    },
    mounted(){
        this.getCultureList()
        this.getCommon()
    },
    methods: {
        async getCultureList(){
            const res = await getCultureListApi({
                title: this.searchContent,
                page: this.page,
                size: 3
            })
            if(res.code === 200){
                this.lastPage = res.data.last_page
                this.shopList = res.data.data
            }
        },
        handleClick(e){
            this.$router.push({
                path: '/culture/collection',
                query: {
                    id: e.culture_id
                }
            })
        },
        handleInput(){
            this.page = 1
            debounce(this.getCultureList, 500)
        },
        changePage(e){
            this.page = e
            this.getCultureList()
        },
        async getCommon(){
            const res = await getCommonApi({
                id: '4'
            })
            console.log('res', res);
            if(res.code === 200){
                this.result = res.data
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.container{
    position: relative;
    padding: 20px 0 120px;

    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    .banner{
        width: 100%;
        position: relative;
        margin-top: -176px;
        z-index: -1;
    }
    .banner-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -400px;
        text-align: center;

        p:nth-child(1){
            font-size: 70px;
            color: #fff;
            margin-bottom: 20px;
            font-weight: bold;
            letter-spacing: 15px;
            -webkit-text-stroke: 2px #fff;
        }
        p:nth-child(2){
            font-size: 26px;
            color: #fff;
        }
    }

    .content, .list-box{
        margin: 200px auto 0;
        max-width: 1400px;

        .title{
            p{
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }
        .text{
            margin-top: 50px;
            margin-bottom: 100px;
            p{
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 20px;
                line-height: 44px;
            }
        }
    }

    .list-box{
        margin: 200px auto 0;
        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .search{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 440px;
                background: #2B2B2B;
                padding: 10px 20px;
                border-radius: 50px;
                border: 1px solid #872228;
                img{
                    width: 42px;
                    height: 42px;
                }
                input{
                    width: 100%;
                    padding: 0 15px;
                    background: transparent;
                    border: none;
                    border-left: 1px solid #fff;
                    height: 36px;
                    margin-left: 15px;
                    font-size: 32px;
                    color: #fff;
                    &:focus-visible{
                        outline: none;
                    }
                }
            }
        }

        
        .list{
            display: flex;
            flex-wrap: wrap;
            max-width: 1760px;
            margin: 0 auto;
            .list-item{
                position: relative;
                width: 28%;
                background: rgba(#000000, 0.3);
                padding: 20px;
                border-radius: 20px;
                margin: 20px calc((100% - (28% + 40px) * 3) / 3 / 2);
                .status-box{
                    position: absolute;
                    width: calc(100% - 60px);
                    top: 40px;
                    left: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    transform: translateX(-50%);
                    .status{
                        padding: 4px 20px;
                        border-radius: 20px;
                        background: rgba(#000000, 0.6);
                        font-size: 24px;
                        border: 1px solid #A3FFB9;
                        color: #A3FFB9;
                    }
                    .time{
                        display: flex;
                        align-items: center;
                        color: #F0F0F0;
                        padding: 5px 20px;
                        background: rgba(#000000, 0.6);
                        border-radius: 20px;
                        font-size: 24px;
                        img{
                            width: 20px;
                            margin-right: 10px;
                        }
                    }
                }

                img{
                    width: 100%;
                    // height: 100%;
                    border-radius: 20px;
                }
                .title{
                    font-size: 40px;
                    color: #fff;
                    margin-top: 20px;
                }   
                .desc{
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-size: 24px;
                    margin-top: 10px;
                    img{
                        width: 35px;
                        height: 31px;
                        margin-right: 10px;
                    }
                }
            }
        }

    }
}
</style>