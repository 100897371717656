<template>
    <pageHeader/>
    <div class="container">
        <img class="bg" src="@/assets/login/login.png" alt="">
        <div class="content">
            <div class="type">
                <div @click="active = 'login'">
                    <img :style="{opacity: active === 'login' ? '1' : '0'}" src="@/assets/login/title1.png" alt="">
                    <p :style="{color: active === 'login' ? '#FFE900' : '#fff'}">{{ $t('zhanghaodenglu') }}</p>
                </div>
                <div @click="active = 'phone'">
                    <img :style="{opacity: active === 'phone' ? '1' : '0'}" src="@/assets/login/title1.png" alt="">
                    <p :style="{color: active === 'phone' ? '#FFE900' : '#fff'}">{{ $t('shoujidenglu') }}</p>
                </div>
            </div>

            <div class="input" v-if="active === 'login'">
                <input v-model="form.mobile" type="text" :placeholder="`(${$t('shurushoujihao')})`">
                <input v-model="form.password" type="password" :placeholder="`(${$t('shurumima')})`">
            </div>
            <div class="input" v-else>
                <input v-model="form.mobile" type="text" :placeholder="`(${$t('shurushoujihao')})`">
                <div class="code">
                    <input v-model="form.code" type="text" :placeholder="`(${$t('shuruqyanzhengma')})`">
                    <span @click="sendSms">{{ btnText }}</span>
                </div>
            </div>
            <div class="forget">

                <router-link to="/forget"><span>{{ $t('Forgot Your Password?') }}</span></router-link>
                <span @click="register">{{ $t('Register') }}</span>
            </div>

            <div class="btn" :style="{background: isCheckbox ? 'linear-gradient(to right, #2A5BE9, #963FF0)' : '#2B2B2B'}" @click="handleLogin">{{ $t('denglu') }}</div>
            <div class="checkbox" @click="isCheckbox = !isCheckbox">
                <img v-show="isCheckbox" src="@/assets/login/checkbox_A.png" alt="">
                <img v-show="!isCheckbox" src="@/assets/login/checkbox.png" alt="">
                {{ $t('yuedubingjieshou') }}<a href="">{{ $t('yonghuxieyitext') }}</a>和<a href="">{{ $t('yinsiceluetext') }}</a>
            </div>
        </div>
    </div>
    <pageFooter/>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import {isMobile} from "@/utils/index"
import {message} from 'ant-design-vue';
import {loginPwdApi, loginSmsApi, loginSmsSendApi} from "@/server/api"

export default {
    components: {
        pageHeader,
        pageFooter
    },
    data() {
        return {
            active: 'login',
            isCheckbox: false,
            btnText: this.$t('fasongyanzhengma'),
            disableSend: false,
            timer: null,
            count: 60,
            form: {
                mobile: '',
                code: '',
                password: '',
            }
        }
    },
    methods: {
        sendSms() {
            if (!this.form.mobile) {
                message.warning('请输入手机号码');
                return false
            }
            if (!isMobile(this.form.mobile)) {
                message.warning('手机号码格式错误');
                return false
            }

            if (this.disableSend) return false

            this.btnText = `${this.count}` + this.$t('chongxinfasongtime');
            this.disableSend = true;
            this.handleSend()
            this.timer = setInterval(() => {
                this.count--
                if (this.count <= 0) {
                    clearInterval(this.timer);
                    this.btnText = '重新发送';
                    this.count = 60;
                    this.disableSend = false;
                    this.timer = null
                } else {
                    this.btnText = `${this.count}` + this.$t('chongxinfasongtime');
                }
            }, 1000);
        },
        async handleSend() {
            const res = await loginSmsSendApi({
                mobile: this.form.mobile
            })
            if (res.code) {
                message.success('验证码已发送')
            }
        },
        async handleLogin() {
            if (!this.isCheckbox) {
                message.warning('请阅读并接受《丝路元宇宙用户协议》和《隐私政策》');
                return false
            }

            if (!isMobile(this.form.mobile)) {
                message.warning('手机号码格式错误');
                return false
            }

            let res = null
            if (this.active === 'login') {
                if (!this.form.mobile) {
                    message.warning('请填写手机号');
                    return false
                }
                if (!this.form.password) {
                    message.warning('请填写密码');
                    return false
                }

                res = await loginPwdApi({
                    mobile: this.form.mobile,
                    password: this.form.password
                })
            } else {
                if (!this.form.mobile) {
                    message.warning('请填写手机号');
                    return false
                }
                if (!this.form.code) {
                    message.warning('请填写验证码');
                    return false
                }

                res = await loginSmsApi({
                    mobile: this.form.mobile,
                    code: this.form.code
                })
            }

            if (res.code === 200) {
                message.success('登录成功');
                localStorage.setItem('token', res.data.token)
                setTimeout(() => {
                    this.$router.push({
                        path: '/user'
                    })
                }, 1000)
            } else {
                message.warning(res.message);
            }
        },
        register() {
            this.$router.push({
                path: '/register'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 20px 0 120px;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .content {
        width: 580px;
        margin: 0 auto;

        .type {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            > div {
                p {
                    margin-top: -66px;
                    font-size: 44px;
                    color: #fff;
                    font-weight: bold;
                }
            }
        }

        .input {
            margin-top: 80px;

            > input {
                width: 580px;
                height: 82px;
                padding: 0 40px;
                font-size: 28px;
                color: #fff;
                margin-bottom: 46px;
                outline: none;
                border: none;
                background: rgba(#000, 0.3);
                border-radius: 40px;
                border: 1px solid rgba(#000, 0.3);

                &:focus-visible {
                    border: 1px solid #FFB11B;
                }
            }

            .code {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 580px;
                height: 82px;
                color: #fff;
                font-size: 28px;
                padding: 0 40px;
                background: rgba(#000, 0.3);
                border-radius: 40px;
                margin-bottom: 46px;

                input {
                    width: 200px;
                    font-size: 28px;
                    color: #fff;
                    outline: none;
                    border: none;
                    background: transparent;
                }

                span {
                    text-align: center;
                    height: 40px;
                    padding-left: 10px;
                    font-size: 26px;
                    line-height: 40px;
                    border-left: 1px solid #eee;
                    cursor: pointer;
                }
            }
        }

        .forget {
            text-align: right;
            cursor: pointer;

            span {
                font-size: 26px;
                color: #fff;
                padding: 0 20px;

                &:first-child {
                    border-right: 1px solid #fff;
                }

                &:hover {
                    color: #FFB11B;
                }
            }
        }

        .btn {
            width: 578px;
            height: 80px;
            border-radius: 40px;
            color: #fff;
            text-align: center;
            font-size: 30px;
            line-height: 80px;
            margin-top: 70px;
            background: linear-gradient(to right, #2A5BE9, #963FF0);
        }

        .checkbox {
            display: flex;
            align-items: center;
            font-size: 22px;
            color: #fff;
            margin-top: 48px;
            cursor: pointer;

            img {
                width: 48px;
                height: 48px;
                margin-right: 10px;
            }
        }
    }
}
</style>