<template>
    <div>
        <div class="container">
            <div class="center">
                <img class="banner" :src="info.picture" alt="">
                <div class="detail" :style="{marginTop: price ? '-180px' : '-240px'}">
                    <div v-if="!address" class="status-box">
                        <div class="status" :style="statusStyle(info.state)">{{ statusMap(info.state) }}</div>
                        <div class="time">
                            <img src="@/assets/icon/time.png" alt="">
                            {{ info.date }}
                        </div>
                    </div>  
                    <p class="title">{{ info.title }}</p>
                    <template v-if="!address">
                        <div class="name">
                            <img src="@/assets/shehui/image2.png" width="48" height="48" alt="">
                            <p>{{ info.name }}</p>
                        </div>
                        <div class="number">
                            <div>{{ $t('huodong') }}</div>
                            <p>{{info.number}}{{ $t('fen') }}</p>
                        </div>
                        <div class="price" v-if="price">￥ <span>{{ info.price }}</span></div>
                    </template>
                    <div v-else class="address-box">
                        <div class="address">
                            <img src="@/assets/icon/address.png" alt="">
                            {{ info.address || '---' }}
                        </div>
                        <div class="time">
                            <img src="@/assets/icon/time.png" alt="">
                            {{ $t('jiarushijian') }}：{{ info.date }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { statusMap, statusStyle } from "@/utils/index.js"
export default {
    props: {
        price: {
            type: Boolean,
            default: false
        },
        address: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default: {}
        }
    },
    computed:{
        statusMap(){
            return statusMap
        },
        statusStyle(){
            return statusStyle
        }
    }
}
</script>

<style lang="scss" scoped>
.container{
    background: rgba(#87222a, 0.8);
    height: 380px;
    margin-top: 200px;

    .center{
        display: flex;
        align-items: center;
        max-width: 1400px;
        margin: 0 auto;
        .banner{
            width: 560px;
            height: 560px;
            object-fit: cover;
            border-radius: 10px;
            margin-top: -120px;
        }
    }
  
    .detail{
        margin-left: 50px;
        .status-box{
            display: flex;
            align-items: center;
            .status{
                padding: 4px 20px;
                border-radius: 20px;
                background: rgba(#000000, 0.6);
                font-size: 24px;
                margin-right: 20px;
                border: 1px solid #A3FFB9;
                color: #A3FFB9;
            }
            .time{
                display: flex;
                align-items: center;
                color: #F0F0F0;
                padding: 5px 20px;
                background: rgba(#000000, 0.6);
                border-radius: 20px;
                font-size: 24px;
                img{
                    width: 20px;
                    margin-right: 10px;
                }
            }
        }
        .title{
            font-size: 56px;
            color: #fff;
            margin-top: 60px;
        }
        .name{
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 24px;
            margin-top: 10px;
            img{
                border-radius: 50%;
                margin-right: 20px;
            }
        }

        .number{
            display: flex;
            align-items: center;
            color: #F9C230;
            margin-top: 46px;

            div{
                border: 1px solid #F9C230;
                padding: 6px 20px;
                border-radius: 20px;
            }

            p{
                font-size: 30px;
                margin-left: 20px;
            }
        }
        
    }
}
.nav-box{
    display: flex;
    align-items: center;
    ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        li{
            color: #E4E4E4;
            font-size: 24px;
            margin-right: 60px;
            cursor: pointer;
        }
    }
    img{
        width: 56px;
        height: 56px;
    }
}

.price{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    background: linear-gradient(to right, #2A5BE9, #963FF0);
    padding: 0px 25px;
    border-radius: 30px;
    font-size: 40px;
    color: #F9EBCA;
    margin-top: 20px;
}

.address-box{
    color: #fff;
    font-size: 24px;
    .address{
        display: flex;
        align-items: center;
        margin-top: 20px;
        img{
            width: 35px;
            height: 31px;
            margin-right: 10px;
        }
    }
    .time{
        display: flex;
        align-items: center;
        margin-top: 60px;
        img{
            width: 25px;
            height: 30px;
            margin-right: 10px;
        }
    }
}
</style>