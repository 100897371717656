<template>
    <pageHeader/>
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb :list="breadcrumbList"></breadcrumb>
        <div class="content">
            <div class="title">{{ detail.title }}</div>
            <div class="banner">
                <img :src="detail.picture" alt="">
            </div>
            <div class="time">{{ detail.date }}</div>
            <div class="text">
                <p type="text" v-html="detail.content"/>
            </div>
            <div class="page">
                <div @click="goDetail(detail.last)" :class="[detail.last ? '' : 'no']">
                    {{ $t('shangyipian') }}: {{ detail.last ? detail.last.title : '---' }}
                </div>
                <div @click="goDetail(detail.next)" :class="[detail.next ? '' : 'no']">
                    {{ $t('xiayipian') }}：{{ detail.next ? detail.next.title : '---' }}
                </div>
            </div>
        </div>

        <div class="content-title">
            <img class="title-bg" src="@/assets/shehui/title.png" alt="">
            <p>{{ $t('shehuizeren') }}</p>
        </div>
        <div class="other-content">
            <!-- <div class="other-item" v-for="item in list" :key="item.society_id" @click="goDetail(item)">
                <img :src="item.picture" alt="" style="object-fit: cover">
                <p class="title">{{ item.title }}</p>
                <p class="time">{{ item.date }}</p>
            </div> -->
            <swiper
                :autoplay="{
                    delay: 100,
                    disableOnInteraction: false,
                    waitForTransition: true,
                    pauseOnMouseEnter: true,
                }"
                loop
                :speed="2000"
                :modules="modules"
                :slides-per-view="5"
            >
                <swiper-slide v-for="item in list" :key="item.society_id" @click="goDetail(item)">
                    <div class="other-item">
                        <img :src="item.picture" alt="" style="object-fit: cover">
                        <p class="title">{{ item.title }}</p>
                        <p class="time">{{ item.date }}</p>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
    <pageFooter/>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import breadcrumb from '@/components/breadcrumb'
import {getSocialListApi, getSocialDetailApi} from "@/server/api"

import {Swiper, SwiperSlide} from 'swiper/vue'
import {Autoplay} from 'swiper/modules';
import 'swiper/css'

let _this = null
export default {
    components: {
        pageHeader,
        pageFooter,
        breadcrumb,
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            id: '',
            breadcrumbList: [{name: this.$t('shehuizeren'), link: '/socialResponsibility'}],
            list: [],
            detail: {},
            modules: [Autoplay]
        }
    },
    mounted() {
        _this = this
        this.id = this.$route.query.id
        this.getSocialList()
    },
    methods: {
        async getSocialList() {
            let res = await getSocialListApi()
            if (res.code === 200) {
                this.list = res.data
                if (!this.id) {
                    this.id = res.data[0].society_id
                }
                this.getSocialDetail()
            }
        },
        async getSocialDetail() {
            let res = await getSocialDetailApi({
                id: this.id
            })
            if (res.code === 200) {
                this.detail = res.data
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }
        },
        goDetail(item) {
            if (item) {
                this.id = item.society_id
                this.getSocialDetail()
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 20px 0 200px;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: -1;
    }

    .content {
        max-width: 1400px;
        padding: 75px 0;
        margin: 0 auto;

        .banner {
            width: 100%;
            text-align: center;
            margin-bottom: 40px;

            img {
                margin: 0 auto;
            }
        }

        .title {
            font-size: 44px;
            color: #fff;
            text-align: center;
            margin-bottom: 40px;
        }

        .time {
            font-family: 'smaller', sans-serif;
            font-size: 40px;
            color: #f0f0f0;
            text-align: center;
            margin-top: 20px;
        }

        .text {
            margin: 90px auto 0;

            p {
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 10px;
                line-height: 44px;
            }
        }

        .page {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            margin-top: 100px;
            font-size: 30px;
            cursor: pointer;

            >div{
                width: 40%;
                line-height: 40px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .no {
                color: #C9C9C9;
            }
        }
    }

    .content-title {
        max-width: 1400px;
        margin: 100px auto 82px;

        p {
            font-size: 98px;
            font-weight: bold;
            color: #ffe900;
            margin-top: -130px;
            letter-spacing: 15px;
            //-webkit-text-stroke: 2px #ffe900;
            white-space: pre;
        }
    }

    .other-content {
        width: 100%;
        // display: flex;
        // align-items: center;
        // overflow-x: auto;

        // &::-webkit-scrollbar {
        //     display: none;
        // }

        padding-bottom: 30px;

        // &::-webkit-scrollbar {
        //     /*滚动条整体样式*/
        //     height: 12px;
        // }

        // &::-webkit-scrollbar-thumb {
        //     /*滚动条里面小方块*/
        //     border-radius: 10px;
        //     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //     background: #862228;
        // }

        // &::-webkit-scrollbar-track {
        //     /*滚动条里面轨道*/
        //     // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        //     border-radius: 10px;
        //     background: rgba($color: #fff, $alpha: 0.1);
        // }

        // &::-webkit-scrollbar-track {
        //     background-color: #f2f2f2;
        // }
        // &::-webkit-scrollbar-thumb {
        //     background-color: #888;
        //     border-radius: 5px;
        // }
        .other-item {
            width: 320px;
            padding: 0 23px;

            img {
                width: 320px;
                height: 320px;
            }

            .title {
                font-size: 30px;
                color: #fff;
                margin: 4px 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .time {
                font-size: 20px;
                color: #f0f0f0;
            }
        }
    }
}
</style>