import { createI18n } from 'vue-i18n'
import zh from './zh'
import en from './en'

const messages = {
    en,
    zh
}
// 这是获取当前浏览器的语言
const language = (navigator.language || 'en').toLocaleLowerCase()
if(!localStorage.getItem('language')){
    localStorage.setItem('language', 'en')
}
const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('language') || language.split('-')[0] || 'en',
    fallbackLocale: 'en', // 设置备用语言
    messages
})

export default i18n