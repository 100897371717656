<template>
    <pageHeader/>
    <div class="container">
        <img class="bg" src="@/assets/login/zhuce.png" alt="">
        <div class="content">
            <div class="left">
                <div class="title">
                    <img src="@/assets/login/title2.png" alt="">
                    <p>欢迎注册 <br> 丝路元宇宙</p>
                </div>
                <div
                    class="btn"
                    :style="{ background: isCheckbox ? 'linear-gradient(to right, #2A5BE9, #963FF0)' : '#2B2B2B' }"
                    @click="handleRegister"
                >注册
                </div>
                <div class="forget">
                    <span @click="goLogin">{{ $t('gologinin') }}</span>
                </div>
                <div class="checkbox" @click="isCheckbox = !isCheckbox">
                    <img v-show="isCheckbox" src="@/assets/login/checkbox_A.png" alt="">
                    <img v-show="!isCheckbox" src="@/assets/login/checkbox.png" alt="">
                    {{ $t('yuedubingjieshou') }}<a href="">{{ $t('yonghuxieyitext') }}</a>和<a href="">{{ $t('yinsiceluetext') }}</a>
                </div>
            </div>
            <div class="right">
                <div class="form">
                    <div class="form-item">
                        <p class="label">账号</p>
                        <input v-model="form.username" type="text" :placeholder="`(${$t('shuruzhanghao')})`">
                    </div>
                    <div class="form-item">
                        <p class="label">密码</p>
                        <input v-model="form.password" type="password" :placeholder="`(${$t('shurudenglumima')})`">
                    </div>
                    <div class="form-item">
                        <p class="label">确认密码</p>
                        <input v-model="form.sure" type="password" :placeholder="`(${$t('shuruzaicimima')})`">
                    </div>
                    <div class="form-item">
                        <p class="label">手机号</p>
                        <input v-model="form.mobile" type="text" :placeholder="`(${$t('shurudengluzhaohuimima')})`">
                    </div>
                    <div class="form-item">
                        <p class="label">验证码</p>
                        <div class="code">
                            <input v-model="form.code" type="text" :placeholder="`(${$t('shuruqyanzhengma')})`">
                            <span @click="sendSms">{{ btnText }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <pageFooter/>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import {registerApi, registerSmsSendApi} from "@/server/api"
import {message} from 'ant-design-vue';
import {isMobile} from "@/utils/index"

export default {
    components: {
        pageHeader,
        pageFooter
    },
    data() {
        return {
            isCheckbox: false,
            btnText: this.$t('fasongyanzhengma'),
            disableSend: false,
            timer: null,
            count: 60,
            form: {
                username: '',
                mobile: '',
                code: '',
                password: '',
                sure: ''
            }
        }
    },
    mounted() {

    },
    methods: {
        sendSms() {
            if (!this.form.mobile) {
                message.warning('请输入手机号码');
                return false
            }
            if (!isMobile(this.form.mobile)) {
                message.warning('手机号码格式错误');
                return false
            }

            if (this.disableSend) return false

            this.btnText = `${this.count}` + this.$t('chongxinfasongtime');
            this.disableSend = true;
            this.handleSend()
            this.timer = setInterval(() => {
                this.count--
                if (this.count <= 0) {
                    clearInterval(this.timer);
                    this.btnText = '重新发送';
                    this.count = 60;
                    this.disableSend = false;
                    this.timer = null
                } else {
                    this.btnText = `${this.count}` + this.$t('chongxinfasongtime');
                }
            }, 1000);
        },
        async handleSend() {
            const res = await registerSmsSendApi({
                mobile: this.form.mobile
            })
            if (res.code) {
                message.success('验证码已发送')
            }
        },
        async handleRegister() {
            if (!this.isCheckbox) {
                message.warning('请阅读并接受《丝路元宇宙用户协议》和《隐私政策》');
                return false
            }

            for (let key in this.form) {
                const value = this.form[key];
                if (!value) {
                    message.warning('请填写完整信息');
                    return false
                }
            }

            if (!isMobile(this.form.mobile)) {
                message.warning('手机号码格式错误');
                return false
            }

            if (this.form.password !== this.form.sure) {
                message.warning('两次密码不一致，请检查');
                return false
            }

            const res = await registerApi({
                username: this.form.username,
                mobile: this.form.mobile,
                code: this.form.code,
                password: this.form.password,
            })

            console.log('res');
            if (res.code === 200) {
                message.success('账号注册成功');
                localStorage.setItem('token', res.data.token)
                setTimeout(() => {
                    this.goLogin()
                }, 1000)
            } else {
                message.error(res.message);

            }
        },

        goLogin() {
            this.$router.push({
                path: '/login'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 20px 0 120px;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1300px;
        margin: 0 auto;

        .left {
            .title {
                font-size: 88px;
                line-height: 150px;
                color: #ffe900;
                font-weight: bold;
                letter-spacing: 15px;
                //-webkit-text-stroke: 2px #ffe900;
                p {
                    margin-top: -320px;
                }

                img {
                    width: 660px;
                }
            }

            .btn {
                width: 578px;
                height: 80px;
                border-radius: 40px;
                color: #fff;
                text-align: center;
                font-size: 30px;
                line-height: 80px;
                margin-top: 70px;
                background: linear-gradient(to right, #2A5BE9, #963FF0);
            }

            .forget {
                width: 578px;
                text-align: center;
                cursor: pointer;
                margin-top: 20px;
                margin-right: 60px;

                span {
                    font-size: 26px;
                    color: #fff;
                    padding: 0 20px;

                    &:hover {
                        color: #FFB11B;
                    }
                }
            }

            .checkbox {
                display: flex;
                align-items: center;
                font-size: 22px;
                color: #fff;
                margin-top: 48px;
                cursor: pointer;

                img {
                    width: 48px;
                    height: 48px;
                    margin-right: 10px;
                }
            }
        }

        .right {
            width: 580px;
            margin-top: 100px;

            .form {

                .form-item {
                    .label {
                        color: #F0F0F0;
                        font-size: 26px;
                        margin-bottom: 10px;
                        padding-left: 40px;
                    }

                    > input {
                        width: 500px;
                        height: 82px;
                        padding: 0 40px;
                        font-size: 28px;
                        color: #fff;
                        margin-bottom: 46px;
                        outline: none;
                        border: none;
                        background: rgba(#000, 0.3);
                        border-radius: 40px;
                        border: 1px solid #000000;

                        &:focus-visible {
                            border: 1px solid #FFB11B;
                        }
                    }
                }

                .code {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 500px;
                    height: 82px;
                    color: #fff;
                    font-size: 28px;
                    padding: 0 40px;
                    background: rgba(#000, 0.3);
                    border-radius: 40px;
                    margin-bottom: 46px;
                    border: 1px solid #000000;

                    &:focus-visible {
                        border: 1px solid #FFB11B;
                    }

                    input {
                        width: 200px;
                        font-size: 28px;
                        color: #fff;
                        outline: none;
                        border: none;
                        background: transparent;
                    }

                    span {
                        text-align: center;
                        height: 40px;
                        padding-left: 10px;
                        border-left: 1px solid #eee;
                        font-size: 26px;
                        line-height: 40px;
                        cursor: pointer;
                    }
                }
            }
        }


    }
}
</style>