<template>
    <pageHeader />
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb :list="breadcrumbList"></breadcrumb>
        <div class="content">
            <div class="title">{{ detail.title }}</div>
            <div class="banner">
                <img :src="detail.picture" alt="">
            </div>
            <div class="time">{{ detail.date }}</div>
            <div class="text">
                <p>{{ detail.content }}</p>
            </div>
            <div class="page">
                <div @click="goDetail(detail.last)" :class="[detail.last ? '' : 'no']">
                    {{ $t('shangyipian') }}: {{ detail.last ? detail.last.title : '---' }}
                </div>
                <div @click="goDetail(detail.next)" :class="[detail.next ? '' : 'no']">
                    {{ $t('xiayipian') }}：{{ detail.next ? detail.next.title : '---' }}
                </div>
            </div>
        </div>
    </div>
    <pageFooter />
</template>

<script>
    import pageHeader from '@/components/pageHeader'
    import pageFooter from '@/components/pageFooter'
    import breadcrumb from '@/components/breadcrumb'
    import {getHotspotListApi, getHotspotDetailApi} from "@/server/api"

    export default {
        components: {
            pageHeader,
            pageFooter,
            breadcrumb
        },
        data(){
            return {
                id: '',
                breadcrumbList: [{name: '社会责任热点', link: '/hotspotResponsibility'}],
                list: [],
                detail: {}
            }
        },
        mounted() {
            this.id = this.$route.query.id 
            this.getHotspotList()
        },
        methods: {
            async getHotspotList(){
                let res = await getHotspotListApi()
                console.log(res)
                if(res.code === 200){
                    this.list = res.data
                    if(!this.id){
                        this.id = res.data[0].hotspot_id
                    }
                    this.getHotspotDetail()
                }
            },
            async getHotspotDetail(){
                let res = await getHotspotDetailApi({
                    id: this.id
                })
                console.log(res)
                if(res.code === 200){
                    this.detail = res.data
                }
            },
            goDetail(item){
                if(item){
                    this.id = item.hotspot_id
                    this.getHotspotDetail()
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.container{
    position: relative;
    padding: 20px 0 100px;
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .content{
        max-width: 1400px;
        padding: 75px 0;
        margin: 0 auto;
        .banner{
            width: 100%;
            text-align: center;
            margin-bottom: 40px;
            img{
                margin: 0 auto;
            }
        }
        .title{
            font-size: 44px;
            color: #fff;
            text-align: center;
            margin-bottom: 40px;
        }
        .time{
            font-size: 40px;
            color: #f0f0f0; 
            text-align: center;
        }
        .text{
            margin: 90px auto 0;
            p{
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 10px;
                line-height: 44px;
            }
        }
        .page{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            margin-top: 150px;
            font-size: 30px;
            cursor: pointer;

            >div{
                width: 40%;
                line-height: 40px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            
            .no{
                color: #C9C9C9;
            }
        }
    }
}
</style>