<template>
    <div>
        <transition name="show">
            <div class="container" v-if="showHeaderNav">
                <img src="@/assets/header/logo.png" alt="">
                <div class="nav-box">
                    <ul>
                        <li 
                            v-for="(nav, index) in navList" :key="nav.label" 
                            :class="[nav.active && 'active']"
                            :style="{width: language === 'en' ? nav.index === 3 ? '130px' : '100px' : 'auto'}"
                            @click="clickNav(nav)"
                        >{{ nav.label }}</li>
                    </ul>
                    <img @click="goLogin" src="@/assets/header/avatar.png" alt="">
                    <a-dropdown :trigger="['click']">
                        <a class="dropdown-link" @click.prevent>{{ languageValue }}</a>
                        <template #overlay>
                            <a-menu @click="selectLanguage">
                                <a-menu-item v-for="item in languageList" :key="item.key">
                                    <span>{{item.value}}</span>
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { Dropdown, Menu, MenuItem } from "ant-design-vue"
import { useI18n } from 'vue-i18n'

export default {
    components: {
        ADropdown: Dropdown,
        AMenu: Menu,
        AMenuItem: MenuItem,
    },
    props: {
        index: Boolean,
        currentIndex: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            showHeaderNav: true,
            languageList: [{key: 0, value: '中文', id: 'zh'}, {key: 1, value: 'English', id: 'en'}],
            languageValue: 'English',
            navList: [{
                label: useI18n().t('shouye'),
                path: "/",
                index: 1
            }, {
                label: useI18n().t('shehuizeren'),
                path: "/socialResponsibility",
                index: 3,
                active: false
            }, {
                label: useI18n().t('cishanhuashen'),
                path: "/user/notes",
                index: 6,
                active: false
            }, {
                label: useI18n().t('shanyuanyuzhou'),
                path: "/nft",
                index: 7,
                active: false
            }, {
                label: useI18n().t('wenhuashijie'),
                path: "/culture",
                index: 9,
                active: false
            }, {
                label: useI18n().t('fuchijijin'),
                path: "/support",
                index: 10,
                active: false
            }]
        }
    },
    watch: {
        currentIndex(newVal) {
            this.navList.forEach(nav => {
                nav.active = nav.index == newVal
            })
        }
    },
    computed: {
        language() {
            return localStorage.getItem('language') || 'en'
        }
    },
    mounted() {
        if (this.index) {
            this.navList.forEach(nav => {
                nav.active = nav.index == this.currentIndex
            })
        } else {
            this.navList.forEach(nav => {
                if (nav.path !== '/') {
                    nav.active = this.$route.path.includes(nav.path)
                }
            })
        }

        this.initLanguage()
        
        // if(this.$route.path !== '/'){
        //     const _this = this
        //     let lastScrollPosition = 0;

        //     window.onscroll = function(){
        //         const currentScrollPosition = window.scrollY;
        //         const scrollDirection = currentScrollPosition > lastScrollPosition ? 'down' : 'up';
        //         console.log(scrollDirection);
        //         if(scrollDirection === 'down'){
        //             _this.showHeaderNav = false
        //         }else{
        //             _this.showHeaderNav = true
        //         }
        //         console.log('showHeaderNav', this.showHeaderNav);
        //         // this.showHeaderNav = currentScrollPosition > lastScrollPosition
        //         lastScrollPosition = currentScrollPosition;
        //     }
        // }
    },
    methods: {
        initLanguage(){
            if(!localStorage.getItem('language')) localStorage.setItem('language', 'en')
            
            const loaclLanguage = localStorage.getItem('language')
            this.languageValue = this.languageList.find(lang => lang.id === loaclLanguage).value || 'English'
            const documentTitle = {
                'zh': '丝路元宇宙',
                'en': 'Benegift Silkroad Metaverse'
            }
            document.title = loaclLanguage ? documentTitle[loaclLanguage] : documentTitle['en']
        },
        clickNav(item) {
            if (item.label === '首页') {
                sessionStorage.removeItem('currentPage')
            }
            if (this.index) {
                this.$emit('clickNav', item)
                return false
            }
            this.$router.push({
                path: '/',
                query: {
                    index: item.index
                }
            })
        },
        goLogin() {
            const token = localStorage.getItem('token')
            if (token) {
                this.$router.push({
                    path: '/user'
                })
            } else {
                this.$router.push({
                    path: '/login'
                })
            }
        },
        selectLanguage(e){
            this.languageValue = this.languageList[e.key].value
            localStorage.setItem('language', this.languageList[e.key].id)
            this.$i18n.locale = this.languageList[e.key].id
            location.reload()
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, #f8c330 0, #87222a 30%);
    height: 0.88rem;
    padding: 0 0.76rem;

    >img {
        width: 170px;
    }
}

.nav-box {
    display: flex;
    align-items: center;

    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;

        li {
            color: #E4E4E4;
            font-size: 0.24rem;
            margin-right: 0.6rem;
            text-align: center;
            cursor: pointer;
        }
    }

    img {
        width: 0.56rem;
        height: 0.56rem
    }
}

.active {
    color: #ffe900 !important;
}

.show-enter-active,
.show-leave-active {
    transition: all .3s;
}

.show-enter-from,
.show-leave-to {
    transform: translateY(-100px);
}

.dropdown-link{
    font-size: 0.24rem;
    color: #fff;
    margin-left: 0.4rem;
    cursor: pointer;
}
</style>