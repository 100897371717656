<template>
    <pageHeader />
    <div class="container">
        <img class="bg" src="@/assets/core/bg.png" alt="">
        <breadcrumb></breadcrumb>
        <div class="content">
            <img v-if="result.param1" class="image" style="width: 44%;" align='right' :src="result.param1" alt="">
            <img v-else class="image" style="width: 44%;" align='right' src="@/assets/core/image1.png" alt="">

            <div>
                <div class="title">
                    <img class="title-bg" style="width: 52%;" src="@/assets/core/title.png" alt="">
                    <p>{{ $t('hexinyoushi') }}</p>
                </div>
                <p class="text" v-html="result.param2"/>
            </div>
        </div>
        <div class="content2">
            <img class="bg2" src="@/assets/core/bg2.png" alt="">
            <div class="title">
                <p>{{ $t('M2C2Bshengtaixitong') }}</p>
                <p>Metaverse to Culture to Beneficence</p>
            </div>
            <div class="image2">
                <img src="@/assets/core/image2.png" alt="">
            </div>

            <div class="content-text">
                <p class="text" v-html="result.param3"/>
            </div>
        </div>
    </div>
    <pageFooter />
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import pageHeader from '@/components/pageHeader'
    import pageFooter from '@/components/pageFooter'
    import breadcrumb from "@/components/breadcrumb"
    import {getCommonApi} from "@/server/api"

    onMounted(() => {
        getCommon()
    })

    const result = ref({})
    async function getCommon(){
        const res = await getCommonApi({
            id: '2'
        })
        console.log('res', res);
        if(res.code === 200){
            result.value = res.data
        }
    }

</script>

<style lang="scss" scoped>
.container{
    position: relative;
    padding: 30px 0 0;
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .content{
        max-width: 1400px;
        margin: 80px auto 0;
        
        .image{
            padding-left: 30px;
            padding-bottom: 40px;
        }

        .title{
            padding: 100px 0 10px;
            p{
                font-size: 98px;
                font-weight: bold;
                color: #ffe900;
                margin-top: -130px;
                letter-spacing: 15px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }
        .text{
            font-size: 28px;
            color: #F0F0F0;
            white-space: pre-wrap;
            //text-indent: 2em;
            margin-bottom: 10px;
            line-height: 44px;
        }
    }

    .content2{
        position: relative;
        margin-top: 150px;
        padding-bottom: 180px;
        .bg2{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
        .title{
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            text-align: center;
            transform: translateX(-50%);
            p:first-child{
                font-size: 98px;
                font-weight: bold;
                color: #ffe900;
                margin-top: 0;
                line-height: 100px;
                letter-spacing: 7px;
            }
            p:last-child{
                font-size: 44px;
                color: #ffffff;
                letter-spacing: 7px;
            }
        }
        .image2{
            width: 100%;
            text-align: center;
            padding-top: 14px;
            img{
                width: 1250px;
                margin: 0 auto;
            }
        }
    }

    .content-text{
        width: 1400px;
        margin: 50px auto 0;
        font-size: 28px;
        color: #F0F0F0;
        //text-indent: 2em;
    }
}
</style>