<template>
    <pageHeader />
    <div class="container">
        <img class="bg" src="@/assets/user/bg.png" alt="">
        <div class="content">
            <div class="note">
                <div class="title">
                    <img src="@/assets/user/title1.png" alt="">
                    <p>{{ $t('gonggao') }}</p>
                </div>
                <div class="list">
                    <template  v-for="(notice,index) in noticeList">
                        <div class="list-item" v-if="index < 5" :key="notice.notice_id" @click="seeDetail(notice, $t('gonggao'))">
                            <p>{{notice.title}}</p>
                            <p class="time">{{ notice.updated_at }}</p>
                        </div>
                    </template>
                </div>
            </div>
            <div class="body">
                <img src="@/assets/user/body.png" alt="">
                <div>
                    <div class="more" @click="goEdit">
                        {{ $t('user.xiugaixinxi') }}
                        <img src="@/assets/icon/arrow2.png" alt="">
                    </div>
                    <div class="out" @click="signOut">
                        {{ $t('tuichudenglu') }}
                        <img src="@/assets/icon/out.png" alt="">
                    </div>
                </div>
            </div>
            <div class="email">
                <div class="userInfo">
                    <div class="name"><span class="name_title">{{ $t('user.nicheng') }}：</span>{{ userInfo.nickname || '暂无' }}</div>
                    <div class="desc"><span class="name_title">{{ $t('user.gerenjianjie') }}：</span>{{ userInfo.content || '暂无' }}</div>
                </div>
                <div class="title">
                    <img src="@/assets/user/title1.png" alt="">
                    <p>{{ $t('youjian') }}</p>
                </div>
                <div class="list">
                    <template  v-for="(mail,index) in emailList">
                        <div class="list-item" v-if="index < 5" :key="mail.mail_id" @click="seeDetail(mail, $t('youjian'))">
                            <p>{{mail.title}}</p>
                            <p class="time">{{ mail.updated_at }}</p>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>

    <div v-show="showModal" class="mask"></div>
    <div v-show="showModal" class="modal">
        <div class="header">
            <span>{{ modalDetail.name }}</span>
            <img src="@/assets/icon/close.png" alt="" @click="showModal = false">
        </div>
        <div class="text-content">
            <div class="content-title">
                <p>{{ modalDetail.title }}</p>
                <p>{{ modalDetail.updated_at }}</p>
            </div>  
            <div class="content-con">
                {{ modalDetail.content }}
            </div>  
        </div>
    </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import { message } from 'ant-design-vue';
import {getNoticeApi, getEmailApi, getUserInfoApi} from "@/server/api"

export default {
    components: {
        pageHeader,
        pageFooter
    },
    data() {
        return {
            noticeList: [],
            emailList: [],
            userInfo: {},
            showModal: false,
            modalDetail: {}
        }
    },
    mounted(){
        const token = localStorage.getItem('token')
        if(!token){
            this.$router.push('/login')
            return false
        }
        this.getUserInfo()
        this.getNotice()
        this.getEmail()
    },
    methods: {
        async getNotice(){
            const res = await getNoticeApi()
            if(res.code === 200){
                this.noticeList = res.data
            }
            console.log('res', res);
        },
        async getEmail(){
            const res = await getEmailApi()
            if(res.code === 200){
                this.emailList = res.data
            }
            console.log('res', res);
        },
        async getUserInfo(){
            const res = await getUserInfoApi()
            if(res.code === 200){
                this.userInfo = res.data
            }
            console.log('res', res);
        },

        seeDetail(item, title){
            item.name = title
            this.showModal = true
            this.modalDetail = item
        },
        goEdit(){
            this.$router.push({
                path: '/user/edit'
            })
        },
        signOut(){
            localStorage.removeItem('token')
            sessionStorage.removeItem('currentPage')
            this.$router.push('/login')
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        position: relative;
        padding: 1px;

        .bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        .content{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 50px 70px;
            .title{
                p{
                    font-size: 66px;
                    font-weight: bold;
                    color: #ffe900;
                    letter-spacing: 15px;
                    margin-top: -80px;
                    //-webkit-text-stroke: 2px #ffe900;
                    white-space: pre;
                }
            }

            .note{
                width: 40%;
            }
            .body{
                width: 20%;
                margin: 0 20px;

                > img{
                    display: block;
                    height: calc(100vh - 340px);
                    margin: 0 auto;
                }

                .more, .out{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 260px;
                    font-size: 24px;
                    background: linear-gradient(to right, #2A5BE9, #963FF0);
                    padding: 10px 20px;
                    border-radius: 30px;
                    color: #F0F0F0;
                    margin-top: 40px;
                    cursor: pointer;
                    img{
                        width: 36px;
                        height: 36px;
                        margin-left: 20px;
                    }
                }

                .out{
                    background: #999999;
                    color: #fff;
                    img{
                        width: 32px;
                        height: 32px;
                        margin-left: 20px;
                    }
                    &:hover{
                        opacity: 0.8;
                    }
                }
            }
            .email{
                width: 40%;

                .userInfo{
                    margin-bottom: 60px;
                    .name{
                        font-size: 30px;
                        font-weight: bold;
                        color: #fff;
                    }
                    .desc{
                        font-size: 20px;
                        color: #fff;
                        margin-top: 25px;
                    }
                    .name_title{
                        font-size: 34px;
                        font-weight: bold;
                        color: #FFE900;
                    }
                }
            }

            .list{
                .list-item{
                    border-left: 10px solid #A5292E;
                    padding: 14px;
                    max-width: 600px;
                    margin-top: 30px;
                    cursor: pointer;
                    &:hover{
                        background: #A5292E;
                    }

                    p{
                        font-size: 30px;
                        color: #fff;
                        white-space:nowrap; 
                        overflow:hidden; 
                        text-overflow:ellipsis;
                    }
                    .time{
                        font-size: 24px;
                        color: #BFBFBF;
                        margin-top: 10px;
                    }
                }
                .active{
                    background: #A5292E;
                }
            }
        }
    }


    .mask{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#000000, 0.6);
        z-index: 2;
    }
    .modal{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 900px;
        z-index: 10;
        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #A5292E;
            color: #fff;
            font-size: 38px;
            border-radius: 20px 20px 0 0;
            padding: 25px;
            img{
                cursor: pointer;
            }
        }
        .text-content{
            padding: 25px;
            background: #2B2B2B;
            padding: 36px;
            .content-title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 30px;
                p:first-child{
                    font-size: 34px;
                    color: #fff;
                }
                p:last-child{
                    font-size: 28px;
                    color: #fff;
                }
            }
            .content-con{
                font-size: 28px;
                color: #BFBFBF;
            }
        }
    }
</style>    