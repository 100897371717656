export default {
    guanyuwomen: "About Us",
    hezuohuoban: "Partners",
    siluyuanzhuang: "Benegift Silkroad Metaverse",
    liaojiegengduo: "Learn more",
    dianjiqianwang: "Click to go",
    shouye: "Home Page",
    shehuizeren: "Social Responsibility",
    cishanhuashen: "Charity Incarnation",
    shanyuanyuzhou: "Shanyuan Universe",
    wenhuashijie: "Cultural Envoy",
    fuchijijin: "Benigift Fund",
    hexinyoushi: "Core advantages",
    M2C2Bshengtaixitong: "M2C2B ecosystem",
    shangyipian: "Previous article",
    xiayipian: "Next article",
    anzhuobanxiazai: "ANDROID",
    pingguobanxiazai: "APPLE",
    gonggao: "Announcement",
    nichen: "Nickname",
    gerenjianjie: "Personal profile",
    youjian: "mail",
    xiugaixinxi: "Modifying Information",
    tuichudenglu: "Log out and log in",
    jieshao: 'Introduce',
    shehuizerenredian: "Social Responsibility Hot Topics",
    qifuhuodong: "Prayer activities",
    xiangqing: "Details",
    shuziyishuguan: "Digital Art Museum",
    bowuguan: "Museum",
    jingxuannft: "Featured NFT",
    shengtaixitongxiangqing: "Ecosystem details",
    cangpinliebiao: 'Collection list',
    gerenxinxi: 'Personal information',
    shuoming: 'Illustrate',
    juankuanrukou: 'Donation Portal',

    silutitle: 'Silk Road Public Digital Art Museum',
    siluquanqiu: 'Silk Road Global Cultural Ambassador',
    siluqifutitle: 'Silk Road Public Welfare Digital Blessing Activity',
    cishantitle: 'Charitable incarnation note',
    silufuchijijin: 'Silk Road Benegift Fund',
    shengtaixitong: 'Ecosystem',

    shehuiyiyi: 'Social significance',
    huodong: 'Events',
    fen: 'Unit',
    jiarushijian: 'Add time',
    placeholder: 'Please enter the activity keyword',
    fangshi: 'Way',
    zhuangtai: 'Status',
    leixing: 'Type',
    quanbu: 'All',
    xianshanghuodong: 'Online',
    xianxiahuodong: 'Offline',
    liandonghuodong: 'Linkage',
    weikaishi: 'Not yet started',
    jinxingzhong: 'Under way',
    yijieshu: 'Ended',
    placeholder2: 'Please enter the keyword',
    placeholder3: 'Please enter ',
    liuchengtitle: 'Event gift collection display, click to view the collection process',
    liucheng: 'Flow',
    huoqufangshi: 'Acquisition mode',
    huodongxiangqing: 'Event details',
    lishi: 'History',
    shiming: 'Mission',
    jijindeshiyong: 'Use of funds',
    tesegongneng: 'Characteristic function',
    quanqiumengguan: 'Art Museums',
    canyufangshi: 'Participation Mode',
    zhanghaodenglu: 'Account',
    shoujidenglu: 'Mobile',
    denglu: 'Log in',
    dianhua: 'telephone',
    youxiang: 'mailbox',
    dizhi: 'address',
    bowuguanguanjianzi: 'Please enter museum keywords',

    shurushoujihao: 'Please enter your phone number',
    shuruxinmima: 'Please enter a new password',
    shuruquerenmima: 'Please confirm the new password',
    shuruqyanzhengma: 'Please enter the verification code',

    shurumima: 'Please enter your password',
    jieshaoziji: 'Introduce yourself...',

    shuruzhanghao: 'Please enter your account number',
    shurudenglumima: 'Please enter your login password',
    shuruzaicimima: 'Please re-enter your password',
    shurudengluzhaohuimima: 'Used for login and password retrieval',

    gologinin: 'Go Login',
    yuedubingjieshou: 'Read and accept ',
    yonghuxieyitext: 'Silk Road Metaverse User Agreement ',
    yinsiceluetext: 'Privacy Policy',

    fasongyanzhengma: 'Send verification code',
    querenxiugai: 'Confirm modifications',
    chongxinfasongtime: ' seconds resend after',

    user: {
        xiugaixinxi: 'Modify information',
        nicheng: 'Nickname',
        xingbie: 'Sex',
        cishanshezhi: 'Charity avatar setup',
        cishanshuoming: 'Charitable incarnation note',
        gerenjianjie: 'Personal profile',
        shiyongzhong: 'In use',
        baocun: 'Save',
        quxiao: 'Center',
        nan: 'Man',
        nv: 'Women'
    }
};
