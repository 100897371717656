<template>
    <pageHeader />
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb></breadcrumb>
        <img class="banner" src="@/assets/nft/banner.png" alt="">
        <div class="banner-title">
            <img src="@/assets/nft/title.png" alt="">
            <p>{{ $t('jingxuannft') }}</p>
        </div>

        <div class="list-box">
            <shopContainer 
                :shopList="list" 
                :typeList="typeList" 
                type="price" 
                @onChange="handleChange" 
                @onSearch="handleSearch"
                @clickItem="handleClickItem" 
            />
            <pagination :lastPage="lastPage" @onChange="changePage"></pagination>
        </div>
    </div>
    <pageFooter />
</template>

<script >
    import pageHeader from '@/components/pageHeader'
    import pageFooter from '@/components/pageFooter'
    import breadcrumb from '@/components/breadcrumb'
    import shopContainer from '@/components/shopContainer'
    import pagination from "@/components/pagination"
    import { getNftPageApi, getNftTypeApi } from "@/server/api"

    export default {
        components: {
            pageHeader,
            pageFooter,
            breadcrumb,
            shopContainer,
            pagination
        },
        data(){
            return {
                searchContent: '',
                params: {
                    type: '',
                    state: '',
                },
                list: [],
                page: 1,
                lastPage: 0,
                typeList: []
            }
        },
        mounted(){
            this.getNftPage()
            this.getNftType()
        },
        methods: {
            async getNftPage(){
                const res = await getNftPageApi({
                    title: this.searchContent,
                    page: this.page,
                    size: 3,
                    ...this.params
                })
                if(res.code === 200){
                    this.lastPage = res.data.last_page
                    this.list = res.data.data
                }
            },
            async getNftType(){
                const res = await getNftTypeApi()
                if(res.code === 200){
                    this.typeList = res.data
                }
            },
            handleChange(e){
                this.page = 1
                this.params[e.key] = e.value
                this.getNftPage()
            },
            handleSearch(e){
                this.page = 1
                this.searchContent = e
                this.getNftPage()
            },
            handleClickItem(e){
                this.$router.push({
                    path: `/nft/detail`,
                    query: {
                        id: e.design_id
                    }
                })
            },
            changePage(e){
                this.page = e
                this.getNftPage()
            }
        }
    }
</script>

<style lang="scss" scoped>
.container{
    position: relative;
    padding: 20px 0 120px;

    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    .banner{
        width: 100%;
        position: relative;
        margin-top: -176px;
        z-index: -1;
    }
    .banner-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -240px;
        text-align: center;
        p{
            font-size: 100px;
            margin-top: -130px;
            margin-left: -140px;
            color: #fff;
            margin-bottom: 20px;
            font-weight: bold;
            letter-spacing: 15px;
            -webkit-text-stroke: 2px #fff;
        }
    }

    .content{
        margin: 200px auto 0;
        width: 1400px;

        .title{
            p{
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }
        .text{
            margin-top: 50px;
            margin-bottom: 100px;
            p{
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 20px;
                line-height: 44px;
            }
        }
    }

    .list-box{
        margin-top: 100px;
    }
}
</style>