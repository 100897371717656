<template>
    <!-- <router-view></router-view> -->
    <router-view v-slot="{ Component }">
        <keep-alive>
            <component :is="Component" :key="$route.path" v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <component :is="Component" :key="$route.path" v-if="!$route.meta.keepAlive"/>
    </router-view>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {}
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family: 'middle', Arial, sans-serif;
}

strong, span, p {
    font-family: inherit;
}

p {
    margin-bottom: 0;
}
</style>
  
  