<template>
    <pageHeader/>
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb></breadcrumb>
        <img class="banner" src="@/assets/qifu/banner.png" alt="">
        <div class="banner-title">
            <p>NEEDS FUNDING</p>
            <p>{{ $t('siluqifutitle') }}</p>
            <p>SILK ROAD PUBLICK WELFARE DIGITAL BLESSING ACTIVITY</p>
        </div>
        <div class="content">
            <div class="title">
                <img src="@/assets/qifu/title5.png" alt="">
                <p>{{ $t('liucheng') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="result.param1"/>
            </div>
            <div class="title">
                <img src="@/assets/qifu/title4.png" alt="">
                <p>{{ $t('canyufangshi') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="result.param2"/>
            </div>
            <div class="title">
                <img src="@/assets/qifu/title6.png" alt="">
                <p>{{ $t('shehuiyiyi') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="result.param3"/>
            </div>
        </div>
        
        <div class="list-box">
            <p class="title">{{ $t('liuchengtitle') }}</p>
            <shopContainer
                :shopList="shopList"
                :typeList="typeList"
                @onChange="handleChange"
                @onSearch="handleSearch"
                @clickItem="handleClickItem"
            />
            <pagination :lastPage="lastPage" @onChange="changePage"></pagination>
        </div>

        <enter/>
    </div>
    <pageFooter/>
</template>

<script setup>
import {ref, onMounted} from 'vue'

import {getCommonApi} from "@/server/api"

onMounted(() => {
    getCommon()
})

const result = ref({})

async function getCommon() {
    const res = await getCommonApi({
        id: '7'
    })
    console.log('res', res);
    if (res.code === 200) {
        result.value = res.data
    }
}

</script>

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import breadcrumb from '@/components/breadcrumb'
import shopContainer from '@/components/shopContainer'
import enter from "@/components/enter.vue"
import pagination from "@/components/pagination"
import {getDesignPageApi, getDesignDetailApi, getDesignTypeApi} from "@/server/api"

export default {
    components: {
        pageHeader,
        pageFooter,
        breadcrumb,
        shopContainer,
        enter,
        pagination
    },
    data() {
        return {
            searchContent: '',
            params: {
                type: '',
                state: '',
            },
            list: [],
            page: 1,
            lastPage: 0,
            shopList: [],
            typeList: [],
        }
    },
    mounted() {
        this.getDesignPage()
        this.getDesignType()
    },
    methods: {
        async getDesignPage() {
            const res = await getDesignPageApi({
                title: this.searchContent,
                page: this.page,
                size: 3,
                ...this.params
            })
            if (res.code === 200) {
                this.lastPage = res.data.last_page
                this.shopList = res.data.data
            }
        },
        async getDesignType() {
            const res = await getDesignTypeApi()
            console.log('res', res);
            if (res.code === 200) {
                this.typeList = res.data
            }
        },
        handleChange(e) {
            this.page = 1
            this.params[e.key] = e.value
            this.getDesignPage()
        },
        handleSearch(e) {
            this.page = 1
            this.searchContent = e
            this.getDesignPage()
        },
        handleClickItem(e) {
            this.$router.push({
                path: '/prayer/detail',
                query: {
                    id: e.design_id
                }
            })
        },
        changePage(e) {
            this.page = e
            this.getDesignPage()
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 20px 0 120px;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .banner {
        width: 100%;
        position: relative;
        margin-top: -176px;
        z-index: -1;
    }

    .banner-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -450px;
        text-align: center;

        p:first-child {
            font-size: 32px;
            background: #F9C230;
            border-radius: 30px;
            color: #000;
            padding: 4px 20px;
            margin-bottom: 20px;
        }

        p:nth-child(2) {
            font-size: 70px;
            color: #fff;
            margin-bottom: 20px;
            font-weight: bold;
            letter-spacing: 15px;
            -webkit-text-stroke: 2px #fff;
        }

        p:nth-child(3) {
            font-size: 26px;
            color: #fff;
        }
    }

    .content {
        margin: 200px auto 0;
        max-width: 1400px;

        .title {
            p {
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }

        .text {
            margin-top: 50px;
            margin-bottom: 100px;

            p {
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 20px;
                line-height: 44px;
            }
        }
    }

    .list-box {
        .title {
            text-align: center;
            color: #FFE900;
            font-size: 54px;
            font-style: italic;
            margin: 30px 0;
        }
    }
}
</style>