<template>
    <pageHeader />
    <div class="container">
        <breadcrumb></breadcrumb>
        <img class="bg" src="@/assets/core/bg.png" alt="">
        <div class="content">
            <img v-if="result.param1" class="image" style="width: 44%;" align='left' :src="result.param1" alt="">
            <img v-else class="image" style="width: 44%;" align='left' src="@/assets/about/image1.png" alt="">
            <div class="title">
                <img class="title-bg" style="width: 52%;" src="@/assets/about/title.png" alt="">
                <p>{{ $t('guanyuwomen') }}</p>
            </div>
            <p class="text" v-html="result.param2" />
        </div>
    </div>
    <pageFooter />
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue'
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import breadcrumb from "@/components/breadcrumb"

import { useI18n } from 'vue-i18n'
import { getCommonApi } from "@/server/api"

const { t } = useI18n()

onMounted(() => {
    getCommon()
})

const result = ref({})
async function getCommon() {
    const res = await getCommonApi({
        id: '1'
    })
    console.log('res', res)
    if (res.code === 200) {
        result.value = res.data
    }
}

</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 30px 0 120px;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .content {
        max-width: 1400px;
        margin: 80px auto 0;

        .image {
            padding-right: 40px;
            padding-bottom: 40px;
        }

        .title {
            padding: 100px 0 10px;

            p {
                font-size: 98px;
                font-weight: bold;
                color: #ffe900;
                margin-top: -135px;
                letter-spacing: 15px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }

        .text {
            font-size: 28px;
            color: #F0F0F0;
            white-space: pre-wrap;
            //text-indent: 2em;
            margin-bottom: 10px;
            line-height: 44px;
        }
    }
}
</style>